<template>
  <div
    v-click-away="clickAway"
    class="relative h-50"
    :class="{ 'z-search-input-suggestions': showSuggestions }"
  >
    <div
      class="grid grid-cols-[1fr_auto] w-full overflow-hidden bg-white text-gray-600 border rounded-[24px] focus-within:border-purple-500 focus-within:shadow-purple-600"
      role="search"
    >
      <label for="searchinput" class="block col-span-1 row-start-1 relative">
        <span class="sr-only">{{
          label ? label : $texts('search.searchFieldLabel', 'Suchbegriff')
        }}</span>

        <input
          id="searchinput"
          ref="input"
          v-model="searchTermInternal"
          type="search"
          autocomplete="off"
          name="searchterm"
          class="bg-white text-gray-900 focus:outline-none h-[48px] pl-20 search-input w-full relative placeholder:text-gray-700"
          spellcheck="false"
          aria-owns="searchterm-suggestions"
          :aria-label="$texts('search.searchInput', 'Suchbegriff eingeben')"
          :placeholder="searchInputPlaceholder"
          :focus-on-load="focusOnLoad"
          @input="onInput"
          @focus="isFocused = true"
          @search="updateSearchTerm"
        />
      </label>

      <button
        type="submit"
        class="button m-[4px] is-strong row-start-1 mobile-only:is-icon-only"
        @click.prevent="updateSearchTerm"
      >
        <SpriteSymbol name="search" aria-hidden="true" />
        <span class="md:not-sr-only sr-only">
          {{ $texts('search.ctaButton', 'Suchen') }}
        </span>
      </button>
      <div
        v-if="showSuggestions"
        ref="suggestionsList"
        class="col-start-1 col-end-6 border-t border-t-gray-200"
      >
        <div class="sr-only" role="status">
          {{
            $textsPlural(
              'search.numberOfSuggestions',
              suggestions.length,
              '1 Ergebnis verfügbar',
              '@count Ergebnisse verfügbar',
            )
          }}
        </div>
        <KeyboardNavigationProvider
          id="searchterm-suggestions"
          tag="ul"
          aria-expanded="true"
          @escape="focusInput"
        >
          <li v-for="(item, i) in suggestions" :key="item + i">
            <button
              class="px-15 md:px-20 py-10 link font-medium block cursor-pointer md:focus:font-bold w-full text-left truncate text-sm md:text-base"
              @click="$emit('update:modelValue', item)"
            >
              {{ item }}
            </button>
          </li>
        </KeyboardNavigationProvider>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  modelValue?: string | null
  getSuggestions?: (term: string) => Promise<string[]>
  placeholder?: string
  focusOnLoad?: boolean
  label?: string
  completionType?: string
  completionContext?: Record<string, string>
}>()

const { $texts } = useNuxtApp()
const searchTermInternal = ref(props.modelValue || '')
const isFocused = ref(false)
const suggestions = ref<string[]>([])
const suggestionsList = ref<HTMLDivElement | null>(null)
const input = ref<HTMLInputElement | null>(null)

let timeout: number | null = null

const resultsCache: Record<string, string[]> = {}

const emit = defineEmits(['update:modelValue'])

watch(
  () => props.modelValue,
  function (newValue) {
    searchTermInternal.value = newValue || ''
    suggestions.value = []
  },
)

const searchInputPlaceholder = computed(
  (): string =>
    props.placeholder ||
    $texts('search.searchFieldPlaceholder', 'Suchbegriff eingeben'),
)

function updateSearchTerm() {
  emit('update:modelValue', searchTermInternal.value)
}

const interceptor = useFetchInterceptor()

function doGetSuggestions(text: string): Promise<string[]> {
  if (props.getSuggestions) {
    return props.getSuggestions(text)
  }
  return $fetch<string[]>('/api/completion', {
    query: {
      text,
      type: props.completionType,
      ...(props.completionContext || {}),
    },
    ...interceptor,
  })
}

function updateSuggestions(term: string) {
  if (resultsCache[term]) {
    suggestions.value = resultsCache[term]
    return
  }
  timeout = window.setTimeout(async () => {
    const newSuggestions = await doGetSuggestions(term)
    resultsCache[term] = newSuggestions
    // Prevent updating sugestions for a stale search term.
    if (term !== searchTermInternal.value) {
      return
    }

    suggestions.value = newSuggestions
  }, 100)
}

function onInput() {
  if (timeout) {
    window.clearTimeout(timeout)
  }
  if (!props.completionType) {
    return
  }
  if (searchTermInternal.value) {
    updateSuggestions(searchTermInternal.value)
    return
  }

  suggestions.value = []
}

const showSuggestions = computed<boolean>(
  () => !!(suggestions.value.length && isFocused.value && props.completionType),
)

function focusInput() {
  if (!input.value) {
    return
  }

  input.value.focus()
}

function clickAway() {
  isFocused.value = false
}

onMounted(() => {
  if (props.focusOnLoad) {
    focusInput()
  }
})

defineOptions({
  name: 'SearchInput',
})
</script>

<style lang="postcss">
.search-input::-webkit-search-cancel-button {
  @apply appearance-none absolute right-0 w-20 h-20 bg-blue-800 top-1/2 -translate-y-1/2;
  mask: url('~/assets/symbols/close.svg') no-repeat 100% 100%;
}
</style>
